<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    :name-map="nameMap"
    entity-type="signum"
    locale-section="pages.signa"
    field-section="signa"
  >
    <!-- Display info about canonical and non-canonical signa above the form -->
    <template
      v-if="signumInscriptions && signumInscriptions.length > 0"
      #above-form
    >
      <hr />
      <b-row v-for="(item, index) in signumInscriptions" :key="index">
        <b-col>
          {{
            item.canonical
              ? $t("pages.signa.canonicalfor")
              : $t("pages.signa.notcanonicalfor")
          }}
          <router-link
            :to="{
              name: 'inscription',
              params: { id: item.inscription.id }
            }"
          >
            {{ inscription(item.inscription) }}
          </router-link>
        </b-col>
      </b-row>
      <hr />
    </template>
  </BaseDetailSimple>
</template>

<script>
import gql from "graphql-tag";
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "SignumPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        signum1 {
          id: signum1
          signum1
        }
        signum2
      `,
      querySelects: /* GraphQL */ `
        signum1: signa1 {
          id: signum1
          signum1
        }
      `,
      querySignumInscriptions: /* GraphQL */ `
        signumInscriptions {
          canonical
          inscription {
            id
            signumInscriptions {
              canonical
              signum {
                signum1 {
                  signum1
                }
                signum2
              }
            }
          }
        }
      `,
      inputAttributes: {
        signum1: { required: true },
        signum2: { required: true }
      },
      signumInscriptions: []
    };
  },
  methods: {
    inscription(inscription) {
      let primary,
        secondary = [];
      inscription.signumInscriptions.forEach(element => {
        let signum =
          element.signum.signum1.signum1 + " " + element.signum.signum2;
        if (element.canonical) {
          primary = signum;
        } else {
          secondary.push(signum);
        }
      });
      return (
        primary +
        (secondary.length > 0
          ? " (" +
            secondary.sort((a, b) => a.localeCompare(b, "sv")).join(", ") +
            ")"
          : "")
      );
    },
    nameMap(values) {
      return (
        values.signum1 &&
        values.signum2 &&
        values.signum1.signum1 + " " + values.signum2
      );
    }
  },
  apollo: {
    values() {
      return {
        query: gql`
          query signumInscriptions($id: ID) {
            values: signum(id: $id) {
              ${this.querySignumInscriptions}
            }
          }
        `,
        variables: { id: this.$route.params.id },
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          this.signumInscriptions = result.data.values.signumInscriptions || [];
        }
      };
    }
  }
};
</script>
